<template>
  <app-container :icon="texts.icon" colWidth="col-12 col-lg-6" v-if="!isTextLoading">
    <template #header>
      <h1 class="text-dark mb-4">{{ texts.title }}</h1>
    </template>
    <template #body>
      <div class="success">
        <p class="mb-4 text-start" v-html="texts.content"></p>
        <button class="btn" @click="$router.push({ name: texts.button.route })">
          {{ texts.button.text }}
        </button>
      </div>
    </template>
  </app-container>
</template>

<script>
import Utilities from '@/utils/services/Utilities';
import { types } from '@/config/utils/routes';

export default {
  name: 'SuccessPage',
  data() {
    return {
      texts: {},
      isTextLoading: false,
      page: 'success',
    };
  },
  async created() {
    this.isTextLoading = true;
    const path = this.$route.params.path;
    await this.getTexts(path);
    this.isTextLoading = false;
  },
  methods: {
    async getTexts(path) {
      const successTypes = types.success;
      const success = successTypes.find((success) => success.path === path);
      this.page = success.type;
      const texts = await Utilities.getPageTexts(this.page);
      this.texts = texts.success;
    },
  },
};
</script>
